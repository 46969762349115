import { Button, Dialog, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import TopAdBadge from "../../../../../images/topad-badge.png";

const ConfirmationModal = ({ visibility, handleClose, primaryBtnAction }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={visibility} onClose={() => handleClose(false)}>
      <Grid
        container
        direction="column"
        alignItems="center"
        className="feature-ad-confirm-modal"
      >
        <img src={TopAdBadge} alt="top-ad-badge" />
        <Typography variant="h6" className="feature-ad-confirm-modal-title">
          {t("setting.featureAd")}
        </Typography>
        <Typography
          variant="body2"
          className="feature-ad-confirm-modal-body-txt"
        >
          {t("setting.featureAdConfirmation")}
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="center"
          className="column-gap-8 margin-top-16"
        >
          <Button
            className="feature-ad-confirm-modal-secondary-btn"
            onClick={() => handleClose(false)}
          >
            {t("common.no")}
          </Button>
          <Button
            className="feature-ad-confirm-modal-primary-btn"
            onClick={primaryBtnAction}
          >
            {t("common.yes")}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ConfirmationModal;
