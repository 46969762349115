import httpAdapter, {
  ApiVersion,
  ContentType,
  HttpMethod,
  ResponseType,
} from "../../../utils/http-adapter";

const PostServiceApi = {
  searchPosts: async ({ authenticated, filters }) =>
    await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: "posts/search",
      authneticated: authenticated,
      queryParams: filters,
    }),
  getPostImages: async ({ postId, imageId, thumbnail }) => {
    return await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: `account/posts/${postId}/images/${imageId}?thumbnail=${thumbnail}`,
      authneticated: true,
      responseType: ResponseType.BLOB,
    });
  },
  getMatchImages: async ({ postId, matchId, imageId, thumbnail }) => {
    return await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: `account/posts/${postId}/matches/${matchId}/images/${imageId}/?thumbnail=${thumbnail}`,
      authneticated: true,
      responseType: ResponseType.BLOB,
    });
  },
  getPostHoroscopeImage: async ({ postId, imageId }) => {
    return await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: `account/posts/${postId}/horoscope_images/${imageId}`,
      authneticated: true,
      responseType: ResponseType.BLOB,
    });
  },
  getMatchHoroscopeImage: async ({ postId, interestId, img }) => {
    return await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: `account/posts/${postId}/matches/${interestId}/images/${img}?thumbnail=false`,
      authneticated: true,
      responseType: ResponseType.BLOB,
    });
  },
  cancelSubscription: async ({ postId }) => {
    return await httpAdapter.request({
      method: HttpMethod.POST,
      apiVersion: ApiVersion.V1,
      path: `account/posts/${postId}/cancel_subscription`,
      authneticated: true,
    });
  },
  saveCard: async ({ postId, data }) => {
    return await httpAdapter.request({
      method: HttpMethod.POST,
      apiVersion: ApiVersion.V1,
      path: `account/posts/${postId}/save_card`,
      authneticated: true,
      body: data,
      contentType: ContentType.FORM_URL_ENCODED,
    });
  },
  makeFeatured: async ({ postId }) => {
    return await httpAdapter.request({
      method: HttpMethod.POST,
      apiVersion: ApiVersion.V1,
      path: `account/posts/${postId}/make_featured`,
      authneticated: true,
    });
  },
  submitMarketingSource: async ({ postId, data }) => {
    return await httpAdapter.request({
      method: HttpMethod.POST,
      apiVersion: ApiVersion.V1,
      path: `account/posts/${postId}/marketing_source`,
      authneticated: true,
      body: data,
      contentType: ContentType.FORM_URL_ENCODED,
    });
  },
};

export default PostServiceApi;
