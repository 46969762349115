import { Grid, Typography } from "@material-ui/core";
import React from "react";
import "../style.css";

const SectionView = ({ title, children }) => {
  return (
    <Grid container direction="column" className="subscription-manage-section-view">
      <Typography
        variant="body1"
        className="section-title"
      >
        {title}
      </Typography>
      {children}
    </Grid>
  );
};

export default SectionView;
