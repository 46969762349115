import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import SectionView from "../../section-view";
import "../../style.css";

const PaymentPendingView = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <SectionView title={t("setting.activateMyAd")}>
      <Grid
        container
        direction="column"
        className="subscription-manage-section-inner-container"
      >
        <Typography variant="body2" className="secondary-txt-color">
          {t("setting.paymentPendingNotice")}
        </Typography>
        <Button
          className="primary-action-btn"
          onClick={() => history.push("/pay-for-ad")}
        >
          <Typography variant="caption" className="font-weight-600">
            {t("setting.payNow")}
          </Typography>
        </Button>
      </Grid>
    </SectionView>
  );
};

export default PaymentPendingView;
