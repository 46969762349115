import { Button, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { makeFeatureAd } from "../../../../v2/features/post";
import { useTranslation } from "react-i18next";
import { PROFILE_STATUS } from "../../../../v2/config/constant";
import { useDispatch } from "react-redux";
import { setAppSnackNotification } from "../../../../v2/store/actions/application";
import SectionView from "../section-view";
import ConfirmationModal from "./confirmation-modal";
import { initializeAuth } from "../../../../v2/features/application";
import { generateTopAdAmount } from "../../../../v2/utils/data-generator";

const FeatureAdSection = ({ authProfile }) => {
  const [isTopAdConfirmationModalVisible, setIsTopAdConfirmationModalVisible] =
    useState(false);

  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const remainingFreeFeaturedCount =
    (typeof authProfile.remainingFreeFeaturedCount === "number" &&
      authProfile.remainingFreeFeaturedCount) ||
    0;

  let featureAdText = "";

  if (authProfile.isFeatured) {
    const featuredEndDate = moment(authProfile.featureUntil).format(
      "YYYY-MM-DD"
    );
    featureAdText = t("setting.topAdExpiryNotice", { date: featuredEndDate });
  } else {
    featureAdText = t("setting.topAdNotice");
  }

  const topAdAmountVisibility = useMemo(() => {
    return (
      authProfile.status !== PROFILE_STATUS.IN_REVIEW &&
      !authProfile.isFeatured &&
      typeof authProfile.remainingFreeFeaturedCount === "number" &&
      !authProfile.remainingFreeFeaturedCount > 0
    );
  }, [authProfile]);

  const onClickFeatureAdBtn = () => {
    if (remainingFreeFeaturedCount) {
      setIsTopAdConfirmationModalVisible(true);
    } else {
      history.push("/payments/top-ad");
    }
  };

  const onConfirmFeaturingAd = async () => {
    const postId = authProfile.id;
    try {
      const response = await makeFeatureAd({ postId });
      const statusCode = response.body?.code ?? undefined;
      if (response.success) {
        dispatch(
          setAppSnackNotification({
            severity: "success",
            message: "Your ad is featured for 7 days",
          })
        );
        setIsTopAdConfirmationModalVisible(false);
        dispatch(initializeAuth());
      } else if (statusCode === 1522) {
        dispatch(
          setAppSnackNotification({
            severity: "error",
            message: "You have no remaining free Top Ad slots available",
          })
        );
      } else {
        dispatch(
          setAppSnackNotification({
            severity: "error",
            message:
              "Unable to feature your ad. Contact support for assistance",
          })
        );
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <SectionView title={t("setting.featureAdHeading")}>
      <Grid
        container
        direction="column"
        className="subscription-manage-section-inner-container"
      >
        <Typography variant="body2" className="secondary-txt-color">
          {featureAdText}
        </Typography>
        {remainingFreeFeaturedCount > 0 && (
          <Typography variant="body2" className="remaining-top-ad-count-txt">
            {t("setting.remainingTopAdText", {
              count: remainingFreeFeaturedCount,
            })}
          </Typography>
        )}

        {topAdAmountVisibility && (
          <Typography className="primary-txt-color">
            {generateTopAdAmount({
              countryCode: authProfile.personalInfo?.residentCountry?.code,
            })}
          </Typography>
        )}

        {authProfile.excludeFromSearch && !authProfile.isFeatured && (
          <Typography variant="caption" className="ad-hidden-notice">
            {t("setting.hiddenAdNotice")}
          </Typography>
        )}
        {!authProfile.isFeatured && (
          <Button className="primary-action-btn" onClick={onClickFeatureAdBtn}>
            <Typography variant="caption" className="font-weight-600">
              {t("setting.featureAd")}
            </Typography>
          </Button>
        )}
      </Grid>
      {isTopAdConfirmationModalVisible && (
        <ConfirmationModal
          visibility={isTopAdConfirmationModalVisible}
          handleClose={setIsTopAdConfirmationModalVisible}
          primaryBtnAction={onConfirmFeaturingAd}
        />
      )}
    </SectionView>
  );
};

export default FeatureAdSection;
