import { Button, Chip, Grid, Typography } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cancelSubscription } from "../../../../v2/features/payment";
import UpdateCardModal from "./update-card-details-dialog-box";
import CloseModal from "./close-modal";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { PROFILE_STATUS } from "../../../../v2/config/constant";
import { setAppSnackNotification } from "../../../../v2/store/actions/application";
import SectionView from "../section-view";
import { initializeAuth } from "../../../../v2/features/application";
import { SUBSCRIPTION_STATUSES } from "../../../../v2/static-data";
import { generateDisplayValueByMeta } from "../../../../v2/utils";
import { isEmpty } from "lodash";

const SubscriptionHandler = () => {
  const [autoRenewalcancelModaVisibility, setAutoRenewalcancelModaVisibility] =
    useState(false);
  const [displayUpdateCardModal, setDisplayUpdateCardModal] = useState(false);

  const { authProfile, authAccount } = useSelector(
    (state) => state.application
  );
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const onClickCancelSubscription = async () => {
    try {
      const response = await cancelSubscription({ postId: authProfile.id });
      if (response.success) {
        dispatch(
          setAppSnackNotification({
            severity: "success",
            message: "Subscription cancelled successfully",
          })
        );
        dispatch(initializeAuth());
        setAutoRenewalcancelModaVisibility(false);
      } else {
        dispatch(
          setAppSnackNotification({
            severity: "error",
            message:
              "Unable to cancel the subscription. Contact support for assistance.",
          })
        );
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const nextAutoRenewalDate = useMemo(() => {
    const adExpireDate = authProfile.expiresAt ?? undefined;
    return adExpireDate ? moment(adExpireDate).format("DD MMMM YYYY") : "";
  }, [authProfile]);

  const autoRenewalDateVisibility = useMemo(() => {
    return !!authProfile.expiresAt;
  }, [authProfile]);

  const postUnderReviewSectionVisibility = useMemo(() => {
    const profileStatus = authProfile.status;
    return (
      !autoRenewalDateVisibility && profileStatus === PROFILE_STATUS.IN_REVIEW
    );
  }, [authProfile, autoRenewalDateVisibility]);

  const getSubscriptionStatus = () => {
    let status = "";
    const subscriptionStatus =
      authProfile.subscriptionEnabled?.toString() || "";
    const statusMeta = SUBSCRIPTION_STATUSES.find(
      (el) => el.id === subscriptionStatus
    );

    if (!isEmpty(statusMeta)) {
      status = generateDisplayValueByMeta({
        meta: statusMeta,
        extractionKey: "label",
        lang: i18n.language,
      });
    }
    return status;
  };

  return (
    <SectionView title={t("setting.manageSubscription")}>
      <Grid
        id="update-card-details"
        container
        direction="column"
        className="subscription-manage-section-inner-container"
      >
        <Grid container direction="row" alignItems="center">
          <Typography
            variant="body2"
            style={{ color: "#333", fontWeight: 600 }}
          >
            Subscription Status -
          </Typography>
          <Typography
            variant="body2"
            style={{ color: "#64A338", fontWeight: 600 }}
          >
            {"\u00A0"}
            {getSubscriptionStatus()}
          </Typography>
        </Grid>

        {autoRenewalDateVisibility && (
          <Grid container direction="column">
            <Typography
              variant="caption"
              className="secondary-txt-color"
              style={{ fontWeight: "lighter" }}
            >
              {t("setting.nextPaymentDate")}
            </Typography>
            <Typography variant="body2" className="primary-txt-color">
              {nextAutoRenewalDate}
            </Typography>
          </Grid>
        )}

        {postUnderReviewSectionVisibility && (
          <Typography variant="body2" className="secondary-txt-color">
            {t("payment.postUnderReview")}
          </Typography>
        )}

        {authAccount.cardSaved && (
          <Button
            onClick={() => setDisplayUpdateCardModal(true)}
            className="primary-action-btn"
          >
            <Typography variant="caption" className="font-weight-600">
              {t("setting.updateCardDetails")}
            </Typography>
          </Button>
        )}

        <Typography
          variant="body2"
          className="cancel-auto-renew-link"
          onClick={() => setAutoRenewalcancelModaVisibility(true)}
        >
          {t("setting.cancelSubscription")}
        </Typography>
      </Grid>
      {displayUpdateCardModal && (
        <UpdateCardModal
          open={displayUpdateCardModal}
          handleClose={() => setDisplayUpdateCardModal(false)}
          authProfile={authProfile}
        />
      )}
      {autoRenewalcancelModaVisibility && (
        <CloseModal
          visibility={autoRenewalcancelModaVisibility}
          handleClose={setAutoRenewalcancelModaVisibility}
          primaryBtnAction={onClickCancelSubscription}
        />
      )}
    </SectionView>
  );
};

export default SubscriptionHandler;
