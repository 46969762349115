export const APP_VARIANT = Object.freeze({
  LOCAL: "local",
  INDIAN: "indian",
});

export const PLATFORMS = Object.freeze({
  IN: "IN",
  LK: "LK",
});

export const DIAL_CODES = Object.freeze({
  IN: "+91",
  LK: "+94",
});

export const APP_LANG = Object.freeze({
  EN: "en",
  SI: "si",
});

export const USER_ROLES = Object.freeze({
  OPERATOR: "OPERATOR",
  OPERATOR_L2: "OPERATOR_L2",
  USER: "USER",
});

export const TARGET_ENVIRONMENTS = Object.freeze({
  DEVELOP: "dev",
  PRODUCTION: "prod",
});

export const UTM_TAG_KEYS = ["utm_campaign", "utm_medium", "utm_source"];
export const OTHER_COUNTRY_CODE = "ZZ";
export const OTHER_PROFESSION_ID = 7;
export const HOUSE_WIFE_PROFESSION_ID = 18;
export const POST_SEARCH_CRITERIA = "post-search-criteria";
export const POST_SEARCH_PREFFERRED_SEARCH_ACTIVE_KEY =
  "post-search-prefferred-search-active";

export const PARENT_TYPE = Object.freeze({
  FATHER: "FATHER",
  MOTHER: "MOTHER",
});

export const SUPPORTED_CURRENCIES = Object.freeze({
  LKR: "LKR",
  INR: "INR",
  USD: "USD",
});

export const CURRENCY_META = Object.freeze([
  {
    code: "LKR",
    label: "Sri Lanka Rupee",
    shortName: "Rs",
    symbol: "Rs",
  },
  {
    code: "USD",
    label: "US Dollar",
    shortName: "USD",
    symbol: "$",
  },
  {
    code: "INR",
    label: "Indian Rupee",
    shortName: "INR",
    symbol: "₹",
  },
]);

export const COUNTRY_CURRENCY_META = Object.freeze([
  {
    code: "LK",
    label: "Sri Lanka",
    translationKey: "common.sriLanka",
    currency: "Rs",
    symbol: "LKR",
  },
  {
    code: "IN",
    label: "India",
    translationKey: "common.india",
    currency: "INR",
    symbol: "₹",
  },
  {
    code: "other",
    label: "Other Countries",
    translationKey: "common.otherCountry",
    currency: "USD",
    symbol: "$",
  },
]);

export const TOP_AD_AMOUNT = Object.freeze({
  LKR: {
    amount: 1000,
    currency: "LKR",
  },
  USD: {
    amount: 6,
    currency: "USD",
  },
});

const Constants = {
  APP_VARIANT,
  META_DESCRIPTION:
    "Find your perfect match in Sri Lanka with Poruwa.lk | Sri Lankan Matrimony , the confidential matrimony site. Browse profiles securely for efficient Sri Lanka marriage proposals. Join us for modern love with traditional values.",
  ALLOWED_MONTHLY_MAGAZINE_APP_VARIANT: [APP_VARIANT.LOCAL],
  UTM_TAG_KEYS,
  APP_LANG,
  OTHER_COUNTRY_CODE: "ZZ",
  LANKA_COUNTRY_CODE: "LK",
  INDIAN_COUNTRY_CODE: "IN",
  JAPAN_COUNTRY_CODE: "JP",
  OTHER_PROFESSION_ID: 7,
  OTHER_PROFESSION_NAME: "Other",
  PARENT_TYPE: Object.freeze({
    FATHER: "FATHER",
    MOTHER: "MOTHER",
  }),
};

export const HttpCode = Object.freeze({
  OK: 200,
  BAD_REQUEST: 400,
});

export const META_DESCRIPTIONS = {
  [PLATFORMS.LK]:
    "Find your perfect match in Sri Lanka with Poruwa.lk | Sri Lankan Matrimony , the confidential matrimony site. Browse profiles securely for efficient Sri Lanka marriage proposals. Join us for modern love with traditional values.",
  [PLATFORMS.IN]:
    "Find your perfect match in Sri Lanka with Poruwa.lk | Sri Lankan Matrimony , the confidential matrimony site. Browse profiles securely for efficient Sri Lanka marriage proposals. Join us for modern love with traditional values.",
};

export const DEPRECATED_PREFFERRED_SEARCH_KEYS_LK = [
  "male",
  "female",
  "showHideInt",
  "ageRange",
  "heightRange",
  "checkedEthnicities",
  "checkedReligions",
  "checkedStatus",
  "checkedProf",
  "minEdu",
  "checkedFoodPref",
  "checkedAccountOwner",
  "checkedSmoking",
  "checkedDissabled",
  "checkedNicVerified",
  "checkedDrinking",
  "checkedResidentCountries",
  "checkedRegions",
];

export const PROFILE_STATUS = {
  INCOMPLETE: "INCOMPLETE",
  PAYMENT_PENDING: "PAYMENT_PENDING",
  IN_REVIEW: "IN_REVIEW",
  LIVE: "LIVE",
  EXPIRED: "EXPIRED",
  DISABLED: "DISABLED",
};

export const PAYMENT_OPTIONS = Object.freeze({
  IS_RECURRING: "isRecurring",
});

export const PAYMENT_IDS = Object.freeze({
  SELECTED_PACKAGE_ID: "SELECTED_PACKAGE_ID",
});

export const PAYMENT_METHOD_IDS = Object.freeze({
  LOCAL_CC: "local_cc",
  FOREIGN_CC: "foreign_cc",
  PAYPAL: "paypal",
});

export default Constants;
