import EnvVar from "./EnvVar";
import {
  APP_LANG,
  PLATFORMS,
  META_DESCRIPTIONS,
  UTM_TAG_KEYS,
  OTHER_COUNTRY_CODE,
  OTHER_PROFESSION_ID,
  HOUSE_WIFE_PROFESSION_ID,
  POST_SEARCH_CRITERIA,
  USER_ROLES,
  POST_SEARCH_PREFFERRED_SEARCH_ACTIVE_KEY,
  DIAL_CODES,
  DEPRECATED_PREFFERRED_SEARCH_KEYS_LK,
  TARGET_ENVIRONMENTS,
  PARENT_TYPE,
  SUPPORTED_CURRENCIES,
  CURRENCY_META,
  PAYMENT_OPTIONS,
} from "./constant";

const commonConfig = {
  NODE_ENV: EnvVar.NODE_ENV,
  API_URL: EnvVar.API_URL,
  CAPTCHA_KEY: EnvVar.CAPTCHA_KEY,
  CAPTCHA_KEY_V2: EnvVar.CAPTCHA_KEY_V2,
  GLOBAL_API_URL: EnvVar.GLOBAL_API_URL,
  WEB_SOCKET_URL: EnvVar.WEB_SOCKET_URL,
  PAYHERE_URL: EnvVar.PAYHERE_URL,
  PAYHERE_PREAPPROVAL_URL: EnvVar.PAYHERE_PREAPPROVAL_URL,
  SENTRY_ID: EnvVar.SENTRY_ID,
  STRIPE_KEY: EnvVar.STRIPE_KEY,
  DOMAIN_NAME: EnvVar.DOMAIN_NAME,
  DOMAIN_DISPLAY_NAME: EnvVar.DOMAIN_DISPLAY_NAME,
  APP_LANGS: APP_LANG,
  PLATFORMS: PLATFORMS,
  CURRENT_PLATFORM: EnvVar.PLATFORM,
  TARGET_ENVIRONMENTS,
  TARGET_ENVIRONMENT: EnvVar.TARGET_ENVIRONMENT,
  USER_ROLES,
  UTM_TAG_KEYS,
  OTHER_COUNTRY_CODE,
  OTHER_PROFESSION_ID,
  HOUSE_WIFE_PROFESSION_ID,
  POST_SEARCH_CRITERIA,
  POST_SEARCH_PREFFERRED_SEARCH_ACTIVE_KEY,
  HOME_PAGE_POST_LIMIT_MOBILE: 10,
  HOME_PAGE_POST_LIMIT_LAPTOP: 20,
  MOBILE_VIEWPORT_UPPER_BOUND: 640,
  PARENT_TYPE,
  CURRENCY_META,
  PRODUCT_LOGO_WHITE: `${process.env.PUBLIC_URL}/images/logo_white.png`,
  PRODUCT_LOGO_BLACK: `${process.env.PUBLIC_URL}/images/logo_black.png`,
  // Plaform Dependent Fields
  DEPRECATED_PREFFERRED_SEARCH_KEYS: [],
  BASE_CURRENCY: SUPPORTED_CURRENCIES.USD,
  SUPPORTED_CURRENCIES,
  META_DESCRIPTION: undefined,
  BASE_COUNTRY_CODE: undefined,
  MAGAZINE_VISIBILITY: false,
  POST_IMAGE_SLOTS: 0,
  POST_HOROSCOPE_IMAGE_SLOTS: 0,
  BLOG_PAGE_VISIBILITY: true,
  OFFLINE_RESPONSES_VISIBILITY: false,
  REVIEWS_AND_RATINGS_VISIBILITY: false,
  TOP_AD_FEATURE_ENABLED: true,
  ETHNICITY_VISIBILITY: false,
  ENABLE_HYTCH: false,
  DIAL_CODE: "",
  TWO_THREE_MONTH_PACKAGES_PROMOS: [],
  SELF_AD_RESTRICTED_FIELD_UPDATABLE_THRSHOLD: 3,
  STORAGE_KEY_POST_CREATE_IDEMPOTENCY: "x-request-id",
  NEW_PRICING_RELEASED_DATE: "",
  PAYMENT_OPTIONS,
};

const getConfigByPlatform = () => {
  const config = { ...commonConfig };
  switch (EnvVar.PLATFORM) {
    case PLATFORMS.LK:
      config.META_DESCRIPTION = META_DESCRIPTIONS.LK;
      config.MAGAZINE_VISIBILITY = true;
      config.ETHNICITY_VISIBILITY = true;
      config.BASE_COUNTRY_CODE = PLATFORMS.LK;
      config.OFFLINE_RESPONSES_VISIBILITY = true;
      config.POST_IMAGE_SLOTS = 3;
      config.POST_HOROSCOPE_IMAGE_SLOTS = 2;
      config.DIAL_CODE = DIAL_CODES.LK;
      config.DEPRECATED_PREFFERRED_SEARCH_KEYS =
        DEPRECATED_PREFFERRED_SEARCH_KEYS_LK;
      config.SUPPORTED_CURRENCIES = [
        SUPPORTED_CURRENCIES.LKR,
        SUPPORTED_CURRENCIES.USD,
      ];
      config.BASE_CURRENCY = SUPPORTED_CURRENCIES.LKR;
      config.INSTAGRAM_URL = "https://www.instagram.com/poruwa.lk";
      config.FACEBOOK_URL = "https://www.facebook.com/poruwaSL";
      config.SUPPORT_EMAIL = "support@poruwa.lk";
      config.MESSENGER_URL = "https://m.me/poruwaSL";
      config.WHATSAPP_URL = "https://wa.me/message/O4IGC3IHIXDQP1";
      config.PHONE_NUMBER = "+94 112 824 814";
      config.PHONE_NUMBER_MOBILE = "+94 707 824 814";
      config.POST_IMAGE_SLOTS = 3;
      config.OFFLINE_RESPONSES_VISIBILITY = true;
      config.REVIEWS_AND_RATINGS_VISIBILITY = true;
      config.TOP_AD_FEATURE_ENABLED = true;
      config.POST_IMAGE_SLOTS = 3;
      config.POST_HOROSCOPE_IMAGE_SLOTS = 2;
      config.DIAL_CODE = DIAL_CODES.LK;
      config.DEPRECATED_PREFFERRED_SEARCH_KEYS =
        DEPRECATED_PREFFERRED_SEARCH_KEYS_LK;
      config.TWO_THREE_MONTH_PACKAGES_PROMOS = ["LOVE20"];
      config.NEW_PRICING_RELEASED_DATE = "2024-11-06T00:00:00";
      break;
    case PLATFORMS.IN:
      config.META_DESCRIPTION = META_DESCRIPTIONS.IN;
      config.BASE_COUNTRY_CODE = PLATFORMS.IN;
      config.POST_IMAGE_SLOTS = 5;
      config.POST_HOROSCOPE_IMAGE_SLOTS = 3;
      config.OTHER_PROFESSION_ID =
        EnvVar.TARGET_ENVIRONMENT === TARGET_ENVIRONMENTS.DEVELOP ? 147 : 181;
      config.HOUSE_WIFE_PROFESSION_ID = 183;
      config.DIAL_CODE = DIAL_CODES.IN;
      config.SUPPORTED_CURRENCIES = [
        SUPPORTED_CURRENCIES.INR,
        SUPPORTED_CURRENCIES.USD,
      ];
      config.BASE_CURRENCY = SUPPORTED_CURRENCIES.INR;
      config.INSTAGRAM_URL = "https://www.instagram.com/myvivah.app";
      config.FACEBOOK_URL =
        "https://www.facebook.com/profile.php?id=61560457538040";
      config.SUPPORT_EMAIL = "support@myvivah.app";
      config.MESSENGER_URL = "https://m.me/355848234270731";
      config.WHATSAPP_URL = "https://wa.me/61494112824";
      config.PHONE_NUMBER = "";
      config.PHONE_NUMBER_MOBILE = "+61 494 112 824";
      break;
    default:
      break;
  }
  return config;
};

const Config = getConfigByPlatform();
export default Config;
