import httpAdapter, {
  ApiVersion,
  ContentType,
  HttpMethod,
} from "../../../utils/http-adapter";

const GenericDataAPI = {
  getGenericFilters: async () =>
    await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: "generic/filters",
      authneticated: false,
    }),
  getAllCountries: async () =>
    await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: "generic/countries",
      authneticated: false,
    }),
  getRegionsByCountryCode: async ({ countryCode }) =>
    await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: `generic/regions?countryCode=${countryCode}`,
      authneticated: false,
    }),
  checkSensitiveData: async ({ content }) =>
    await httpAdapter.request({
      method: HttpMethod.POST,
      apiVersion: ApiVersion.V1,
      path: `generic/check_sensitive_data`,
      body: { content },
      contentType: ContentType.FORM_URL_ENCODED,
    }),
  postFileUpload: async ({ formData }) => {
    return await httpAdapter.request({
      method: HttpMethod.POST,
      apiVersion: ApiVersion.V1,
      path: "generic/upload",
      authneticated: true,
      body: formData,
      contentType: ContentType.FORM_DATA,
    });
  },
  getAppConfig: async () => {
    return await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: "generic/config",
      authneticated: false,
    });
  },
  getCommunitiesByReligionId: async ({ religionId }) => {
    return await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: "generic/communities",
      authneticated: false,
      queryParams: { religionId },
    });
  },
  getPricingTiers: async ({ showNew }) => {
    return await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: "generic/pricing_tiers",
      authneticated: false,
      queryParams: { showNew },
    });
  },
  getReviews: async ({ params }) => {
    return await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: "generic/reviews",
      authneticated: false,
      queryParams: params,
    });
  },
  addVipRequest: async ({ formData }) => {
    return await httpAdapter.request({
      method: HttpMethod.POST,
      apiVersion: ApiVersion.V1,
      path: "generic/vip_requests",
      body: formData,
      contentType: ContentType.FORM_URL_ENCODED,
    });
  },
  getPaymentMethods: async () => {
    return await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: "generic/payment_methods",
      authneticated: false,
    });
  },
  getMarketingSources: async () => {
    return await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: "generic/marketing_sources",
      authneticated: false,
    });
  },
};

export default GenericDataAPI;
