import { Button, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useMemo } from "react";
import { PROFILE_STATUS } from "../../../../../v2/config/constant";
import { useTranslation } from "react-i18next";
import Config from "../../../../../v2/config";
import SectionView from "../../section-view";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const { NEW_PRICING_RELEASED_DATE } = Config;

const RenewalView = ({ authProfile }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const renewalDiscountText = useMemo(() => {
    let text = "";
    const adExpireDate = authProfile.expiresAt ?? "";
    if (adExpireDate) {
      const discount = "20";
      const date = moment
        .utc(moment.utc(adExpireDate).add(7, "days"))
        .local()
        .format("Do MMM");
      text = t("setting.renewalDiscountNotice", {
        date: date,
        discount: discount,
      });
    }
    return text;
  }, [authProfile, t]);

  const displayRenewalDiscount = useMemo(() => {
    let visibility = false;
    const adExpireDate = authProfile.expiresAt ?? "";
    const adPublishedDate = authProfile.firstPublishedAt;
    const displayDiscountForOldPricing = moment(adPublishedDate).isBefore(
      NEW_PRICING_RELEASED_DATE
    );
    if (adExpireDate) {
      const today = moment().local();
      const moment01 = moment(adExpireDate).subtract(7, "days").local();
      const moment02 = moment(adExpireDate).add(7, "days").local();
      const isValidToPromotion = today.isBetween(moment01, moment02);
      if (isValidToPromotion && displayDiscountForOldPricing) {
        visibility = true;
      }
    }
    return visibility;
  }, [authProfile]);

  const displayRenewalSection = useMemo(() => {
    const profileStatus = authProfile.status ?? "";
    return profileStatus === PROFILE_STATUS.EXPIRED || displayRenewalDiscount;
  }, [authProfile, displayRenewalDiscount]);
  return (
    displayRenewalSection && (
      <SectionView title={t("setting.renewMyAd")}>
        <Grid
          container
          direction="column"
          className="subscription-manage-section-inner-container"
        >
          {displayRenewalDiscount && (
            <Typography variant="body2" className="secondary-txt-color">
              {renewalDiscountText}
            </Typography>
          )}
          <Button
            className="primary-action-btn"
            onClick={() => history.push("/pay-for-ad")}
          >
            <Typography variant="caption" className="font-weight-600">
              {t("setting.renewAd")}
            </Typography>
          </Button>
        </Grid>
      </SectionView>
    )
  );
};

export default RenewalView;
