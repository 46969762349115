import { Grid, Typography } from "@material-ui/core";
import React from "react";

const PaymentMethodView = ({ id, label, icon, onSelectMethod }) => {
  return (
    <Grid
      container
      alignItems="center"
      className="payment-method-box"
      onClick={() => onSelectMethod(id)}
    >
      <img src={icon} alt="payment-method" style={{ width: 33 }} />
      <Typography variant="body2">{label}</Typography>
    </Grid>
  );
};

export default PaymentMethodView;
